import React from 'react';
import Search from "./Search";

const SearchContainer = () => {
    return (
        <>
            <Search/>
        </>
    );
};

export default SearchContainer;