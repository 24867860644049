import React, {useEffect, useRef} from 'react';
import ChatRoomList from "./ChatRoomList";

const MessageContainer = () => {
    return (
        <>
            <ChatRoomList/>
        </>
    );
};

export default MessageContainer;